import axios from "axios";

class ApiService {
  // BASE_API_URL = `https://sevtameta.shop:8000`;
  BASE_API_URL=`https://salezwise.com:8000`
  // BASE_API_URL = `http://localhost:4501`;

  reqQueue = {};
  constructor() { }
  async GET(path, h = {}) {
    try {
      const headers = {
        headers: h,
      };
      const res = await axios.get(this.BASE_API_URL + path, headers);
      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error: error.data };
    }
  }
  async DELETE(path, h = {}) {
    try {
      const headers = {
        headers: h,
      };
      const res = await axios.delete(this.BASE_API_URL + path, headers);
      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error: error.data };
    }
  }
  async POST(path, data, h = {}) {
    try {
      const headers = {
        headers: h,
      };
      const res = await axios.post(this.BASE_API_URL + path, data, headers);
      return { data: res.data, error: null };
    } catch (error) {
      return { data: null, error: error.data };
    }
  }
  async loginForm(body) {
    const res = await this.POST(`/api/auth/login`, body);
    if (res.data) {
      localStorage.setItem("token", res.data?.user.Token);
      localStorage.setItem("userId", res.data?.user._id);
    }
    return res.data?.user;
  }

  async createApp(body, consoleId) {
    console.log("consoleId::::", consoleId)
    const allData = {
      addApp: body,
      // location: locationData,
      console_id: consoleId,
      // adx_id: adxId
    };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    console.log("allData+++++++++", allData)

    const res = await this.POST(`/api/auth/createApp`, allData, headers);
    return res?.data?.AppData;
  }
  async getAllAppData(tab, search, pageSize, pageNumber, sort) {
    let queryStr = "";
    if (pageSize && pageSize !== "") {
      queryStr += `&pageSize=${pageSize}`;
    }
    if (pageNumber && pageNumber !== "") {
      queryStr += `&pageNumber=${pageNumber}`;
    }
    if (sort) {
      queryStr += `${queryStr === "" ? '?' : '&'}sort=${sort}`
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    // console.log("pageSize:::::", pageSize)
    // console.log("resGetApp:::+++++:", `/api/auth/getApp?appStatus=${tab}&search=${search}${queryStr}`)
    const res = await this.GET(
      `/api/auth/getApp?appStatus=${tab}&search=${search}&${queryStr}`,
      headers
    );
    // console.log("res======>", res)
    return res.data;
  }

  async createConsole(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.POST(`/api/auth/createConsole`, body, headers);
    return res.data;
  }

  async getAllConsole(search, pageSize, pageNumber, filterData) {
    let queryStr = "";
    if (pageSize && pageSize !== "") {
      queryStr += `?pageSize=${pageSize}`;
    }
    if (filterData && filterData !== "") {
      queryStr += `&consoleStatus=${filterData}`;
    }
    if (pageNumber && pageNumber !== "") {
      queryStr += `&pageNumber=${pageNumber}`;
    }
    if (search && search !== "") {
      queryStr += `&search=${search}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.GET(`/api/auth/getConsole${queryStr}`, headers);
    return res;
  }
  async updateConsole(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.POST(`/api/auth/updateConsole`, body, headers);
    return res.data;
  }
  async deleteConsole(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.DELETE(`/api/auth/deleteConsole/${id}`, headers);
    return res.data;
  }

  async updateApp(body, id, consoleId) {
    // const typeData = type;
    console.log("iddd+++", consoleId)
    const bodyData = {
      id: id,
      ...body,
      // location: locationData,
      console_id: consoleId,
      // adx_id: selectedAdxId

    };
    console.log("bodydataConsole", bodyData.console_id)
    delete bodyData._id
    // bodyData[typeData] = { };
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    console.log("BodyDataApi", bodyData)
    const res = await this.POST(`/api/auth/updateApp`, bodyData, headers);
    return res.data;
  }

  async deleteApp(id) {

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.DELETE(`/api/auth/deleteApp/${id}`, headers);
    return res.data;
  }
  async deletAppClear(id) {

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.DELETE(`/api/auth/deleteApp/${id}?deleted=true`, headers);
    return res.data;
  }
  async uploadImage(data) {
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await this.POST(`/api/auth/uploadImage`, data, headers);
    return res.data;
  }
  async removedApp() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.GET(`/api/auth/getDeletedApp`, headers);
    return res.data
  }
  async getUserDownload(body, filterData, hourWiseData, retention) {
    let queryStr = "";

    if (filterData && filterData !== "") {
      queryStr += `?time=${filterData}`;

    }

    if (retention) {
      queryStr += `${queryStr === "" ? '?' : '&'}retention=true`
    }


    // console.log("retention=true:::", queryStr)
    if (hourWiseData) {
      queryStr += `&hourlyData=${hourWiseData}`;
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.GET(`/api/auth/getUserDownload/${body}${queryStr}`, headers);
    return res?.data
  }

  async restoreData(idData) {
    const body = {
      id: idData
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.POST(`/api/auth/updateDeletedApp`, body, headers);
    return res.data
  }

  async createAdxName(body) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.POST(`/api/auth/createAdex`, body, headers);
    // console.log("AdxRes++", res)
    return res;
  }

  async getAllAdxData() {

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.GET(`/api/auth/getAllAdex`, headers);
    return res;
  }


  async updateAdx(body, id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.POST(`/api/auth/updateAdex/${id}`, body, headers);
    return res;
  }

  async deleteAdxData(id) {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.DELETE(`/api/auth/deleteAdex/${id}`, headers);
    return res;
  }

  async getAllUserCount() {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const res = await this.GET(`/api/auth/allAppUserCount`, headers);
    // console.log("CountAllRes=====", res)
    return res.data;
  }

}
export default new ApiService();
